.venera-image-gallery {

  .venera-image-gallery-item {
    cursor: pointer;
  }

  .venera-image-viewer {
    z-index: 999;
  }
}
