.venera-home {
  .container-sl-fb {
    //background: #ECECEC
  }
  .benefits-widget-container {
    .benefits-widget {
      background-color: #262426;
      @media screen and (min-width: 768px) {
        padding: 20px 35px;
        margin: 0 -50px;
      }

      h1 {
        text-align: center;
        color: #F2E96B;
      }

      ul {
        padding: 0 0 0 5px;
        margin: 0;
        list-style: none;
        text-align: center;

        li {
          display: flex;
          padding: 5px 0 5px 10px;

          .benefit-icon {
            margin: 0 5px 0 0;
            color: #F2E96B;
          }
          .benefit-text {
            word-wrap: break-word;
            color: #F2E96B;
          }

          @media screen and (min-width: 768px) {
            display: inline-flex;
            padding: 5px 10px;
          }
        }
      }
    }
  }

  .spotlight-widget-container {

    .ant-carousel .slick-slide {
      text-align: center;
      height: 160px;
      line-height: 160px;
      background: #735B56;
      overflow: hidden;

      @media screen and (min-width: 768px) {
        height: 511px;
        line-height: 511px;
      }
    }
    .spotlight-heading {
      padding: 0 5px;
      word-break: break-word;
    }

    .ant-card-body {
      padding: 0;
    }
  }
  .top-offer-container {
    .ant-card-body {
      @media screen and (min-width: 768px) {
        height: 250px;
      }
    }

    .top-offer-meta {
      padding: 0 0 5px 0;
    }
  }
}