@charset "UTF-8";
.venera-footer-content ul {
  list-style: none;
  padding: 0;
}

.ant-layout-header {
  padding: 0 10px !important;
}
@media screen and (min-width: 991px) {
  .ant-layout-header {
    padding: 0 50px !important;
  }
}

.menuBar {
  padding: 0 0;
}

.logo {
  height: auto;
  width: 150px;
  padding: 0 10px 0 0;
  float: left;
  color: white;
}

.logo span {
  display: inline-block;
  font-size: 20px;
  text-transform: capitalize;
}

.menuCon {
  /*width: calc(100% - 150px);
  float: left;*/
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 0 20px;
}

.menuCon .ant-menu-item a, .menuCon .ant-menu-submenu-title a {
  padding: 0 15px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: left;
}

.menuCon .rightMenu {
  float: right;
}

.drawer-button-wrapper {
  float: right;
  display: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}

.barsBtn:after, .barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item, .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .language {
    text-align: center;
  }
  .drawer-button-wrapper {
    display: inline-block;
  }
  .leftMenu, .rightMenu {
    display: none;
  }
  .logo a {
    margin-left: -20px;
  }
  .menuCon .ant-menu-item, .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }
  .logo a {
    padding: 10px 20px;
  }
}
.venera-home .benefits-widget-container .benefits-widget {
  background-color: #262426;
}
@media screen and (min-width: 768px) {
  .venera-home .benefits-widget-container .benefits-widget {
    padding: 20px 35px;
    margin: 0 -50px;
  }
}
.venera-home .benefits-widget-container .benefits-widget h1 {
  text-align: center;
  color: #F2E96B;
}
.venera-home .benefits-widget-container .benefits-widget ul {
  padding: 0 0 0 5px;
  margin: 0;
  list-style: none;
  text-align: center;
}
.venera-home .benefits-widget-container .benefits-widget ul li {
  display: flex;
  padding: 5px 0 5px 10px;
}
.venera-home .benefits-widget-container .benefits-widget ul li .benefit-icon {
  margin: 0 5px 0 0;
  color: #F2E96B;
}
.venera-home .benefits-widget-container .benefits-widget ul li .benefit-text {
  word-wrap: break-word;
  color: #F2E96B;
}
@media screen and (min-width: 768px) {
  .venera-home .benefits-widget-container .benefits-widget ul li {
    display: inline-flex;
    padding: 5px 10px;
  }
}
.venera-home .spotlight-widget-container .ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #735B56;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .venera-home .spotlight-widget-container .ant-carousel .slick-slide {
    height: 511px;
    line-height: 511px;
  }
}
.venera-home .spotlight-widget-container .spotlight-heading {
  padding: 0 5px;
  word-break: break-word;
}
.venera-home .spotlight-widget-container .ant-card-body {
  padding: 0;
}
@media screen and (min-width: 768px) {
  .venera-home .top-offer-container .ant-card-body {
    height: 250px;
  }
}
.venera-home .top-offer-container .top-offer-meta {
  padding: 0 0 5px 0;
}

.maps-container {
  padding: 0;
}
@media screen and (min-width: 992px) {
  .maps-container {
    padding: 0 15px;
  }
}

.category-title {
  text-align: center;
  padding: 0 0 15px 0;
}

.pizzeria-search {
  padding: 0 0 15px 0;
}

.pizzeria-offer-container {
  background-color: #262426;
  padding: 20px;
  margin: 0 0 15px 0;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .pizzeria-offer-container {
    padding: 40px;
  }
}
.pizzeria-offer-container .pizzeria-offer-heading {
  margin: 0;
  color: #F2E96B;
}

.pizzeria-price-list, .top-offer-container {
  padding: 0;
}
.pizzeria-price-list .ant-card-grid, .top-offer-container .ant-card-grid {
  padding: 5px;
  border: none;
}
@media screen and (min-width: 991px) {
  .pizzeria-price-list .category-item-pizze .ant-card-body, .top-offer-container .category-item-pizze .ant-card-body {
    height: 230px;
  }
}
@media screen and (min-width: 991px) {
  .pizzeria-price-list .category-item-pizzen .ant-card-body, .top-offer-container .category-item-pizzen .ant-card-body {
    height: 250px;
  }
}
@media screen and (min-width: 768px) {
  .pizzeria-price-list .top-offer-item .ant-card-body, .top-offer-container .top-offer-item .ant-card-body {
    height: 250px;
  }
}
@media screen and (min-width: 991px) {
  .pizzeria-price-list .top-offer-item .ant-card-body, .top-offer-container .top-offer-item .ant-card-body {
    height: 280px;
  }
}
@media screen and (min-width: 991px) {
  .pizzeria-price-list .category-item-tjestenina .ant-card-body, .pizzeria-price-list .category-item-pasta .ant-card-body, .top-offer-container .category-item-tjestenina .ant-card-body, .top-offer-container .category-item-pasta .ant-card-body {
    height: 120px;
  }
}
@media screen and (min-width: 991px) {
  .pizzeria-price-list .category-item-deserti .ant-card-body, .pizzeria-price-list .category-item-dessert .ant-card-body, .top-offer-container .category-item-deserti .ant-card-body, .top-offer-container .category-item-dessert .ant-card-body {
    height: 120px;
  }
}
@media screen and (min-width: 768px) {
  .pizzeria-price-list .category-item-topli-napici .ant-card-body, .pizzeria-price-list .category-item-warme-getränke .ant-card-body, .top-offer-container .category-item-topli-napici .ant-card-body, .top-offer-container .category-item-warme-getränke .ant-card-body {
    height: 160px;
  }
}
@media screen and (min-width: 991px) {
  .pizzeria-price-list .category-item-topli-napici .ant-card-body, .pizzeria-price-list .category-item-warme-getränke .ant-card-body, .top-offer-container .category-item-topli-napici .ant-card-body, .top-offer-container .category-item-warme-getränke .ant-card-body {
    height: 180px;
  }
}
@media screen and (min-width: 768px) {
  .pizzeria-price-list .category-item-gazirana-pića .ant-card-body, .pizzeria-price-list .category-item-getranke-mit-kohlensäure .ant-card-body, .top-offer-container .category-item-gazirana-pića .ant-card-body, .top-offer-container .category-item-getranke-mit-kohlensäure .ant-card-body {
    height: 90px;
  }
}
@media screen and (min-width: 991px) {
  .pizzeria-price-list .category-item-gazirana-pića .ant-card-body, .pizzeria-price-list .category-item-getranke-mit-kohlensäure .ant-card-body, .top-offer-container .category-item-gazirana-pića .ant-card-body, .top-offer-container .category-item-getranke-mit-kohlensäure .ant-card-body {
    height: 130px;
  }
}
@media screen and (min-width: 768px) {
  .pizzeria-price-list .category-item-prirodni-sokovi .ant-card-body, .pizzeria-price-list .category-item-fruchtsäfte .ant-card-body, .top-offer-container .category-item-prirodni-sokovi .ant-card-body, .top-offer-container .category-item-fruchtsäfte .ant-card-body {
    height: 155px;
  }
}
@media screen and (min-width: 991px) {
  .pizzeria-price-list .category-item-prirodni-sokovi .ant-card-body, .pizzeria-price-list .category-item-fruchtsäfte .ant-card-body, .top-offer-container .category-item-prirodni-sokovi .ant-card-body, .top-offer-container .category-item-fruchtsäfte .ant-card-body {
    height: 140px;
  }
}
@media screen and (min-width: 768px) {
  .pizzeria-price-list .category-item-vina .ant-card-body, .pizzeria-price-list .category-item-weine .ant-card-body, .top-offer-container .category-item-vina .ant-card-body, .top-offer-container .category-item-weine .ant-card-body {
    height: 150px;
  }
}
@media screen and (min-width: 991px) {
  .pizzeria-price-list .category-item-vina .ant-card-body, .pizzeria-price-list .category-item-weine .ant-card-body, .top-offer-container .category-item-vina .ant-card-body, .top-offer-container .category-item-weine .ant-card-body {
    height: 155px;
  }
}
.pizzeria-price-list .price-list-item-price-grid, .top-offer-container .price-list-item-price-grid {
  width: 100%;
  font-size: 12px;
  text-align: left;
}
.pizzeria-price-list .price-list-item-price-grid span, .top-offer-container .price-list-item-price-grid span {
  font-size: 15px;
}
.pizzeria-price-list .choice-item, .top-offer-container .choice-item {
  margin: 0 10px 10px 0;
}

.category-items {
  padding: 0 0 15px 0;
}
.category-items .category-item {
  margin: 0 5px 5px 0;
}

.venera-image-gallery .venera-image-gallery-item {
  cursor: pointer;
}
.venera-image-gallery .venera-image-viewer {
  z-index: 999;
}

.tournament-history .title {
  font-style: italic;
  font-size: 24px;
  text-align: center;
  padding: 15px 0;
}

.item-call {
  width: 90% !important;
}

.page-content {
  padding: 0;
  /*@media screen  and(min-width: 768px) {
    padding: 20px 0;
  }*/
}
@media screen and (min-width: 991px) {
  .page-content {
    padding: 0 50px;
  }
}

.loading-container {
  position: absolute;
  bottom: 50%;
  width: 100%;
  text-align: center;
}