@import "../../variables/widths";

.category-title {
  text-align: center;
  padding: 0 0 15px 0;
};

.pizzeria-search {
  padding: 0 0 15px 0;
}

.pizzeria-offer-container {
  background-color: #262426;
  padding: 20px;
  margin: 0 0 15px 0;
  text-align: center;

  @media screen and (min-width: 768px) {
    padding: 40px;
  }

  .pizzeria-offer-heading {
    margin: 0;
    color: #F2E96B;
  }
}

.pizzeria-price-list, .top-offer-container {
  padding: 0;

  .ant-card-grid {
    padding: 5px;
    border: none;
  }

  .category-item-pizze {
    @media screen and (min-width: $lg-width) {
      .ant-card-body {
        height: 230px;
      }
    }
  }

  .category-item-pizzen {
    @media screen and (min-width: $lg-width) {
      .ant-card-body {
        height: 250px;
      }
    }
  }

  .top-offer-item {
    @media screen and (min-width: $md-width) {
      .ant-card-body {
        height: 250px;
      }
    }
    @media screen and (min-width: $lg-width) {
      .ant-card-body {
        height: 280px;
      }
    }
  }

  .category-item-tjestenina, .category-item-pasta {
    @media screen and (min-width: $lg-width) {
      .ant-card-body {
        height: 120px;
      }
    }
  }

  .category-item-deserti, .category-item-dessert {
    @media screen and (min-width: $lg-width) {
      .ant-card-body {
        height: 120px;
      }
    }
  }

  .category-item-topli-napici, .category-item-warme-getränke {
    @media screen and (min-width: $md-width) {
      .ant-card-body {
        height: 160px;
      }
    }

    @media screen and (min-width: $lg-width) {
      .ant-card-body {
        height: 180px;
      }
    }
  }

  .category-item-gazirana-pića, .category-item-getranke-mit-kohlensäure {
    @media screen and (min-width: $md-width) {
      .ant-card-body {
        height: 90px;
      }
    }

    @media screen and (min-width: $lg-width) {
      .ant-card-body {
        height: 130px;
      }
    }
  }

  .category-item-prirodni-sokovi, .category-item-fruchtsäfte {
    @media screen and (min-width: $md-width) {
      .ant-card-body {
        height: 155px;
      }
    }

    @media screen and (min-width: $lg-width) {
      .ant-card-body {
        height: 140px;
      }
    }
  }

  .category-item-vina, .category-item-weine {
    @media screen and (min-width: $md-width) {
      .ant-card-body {
        height: 150px;
      }
    }

    @media screen and (min-width: $lg-width) {
      .ant-card-body {
        height: 155px;
      }
    }
  }

  .price-list-item-price-grid {
    width: 100%;
    font-size: 12px;
    text-align: left;

    span {
      font-size: 15px;
    }
  }

  .choice-item {
    margin: 0 10px 10px 0;
  }
}

.category-items {
  padding: 0 0 15px 0;
  .category-item {
    margin: 0 5px 5px 0;
  }
}