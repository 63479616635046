.ant-layout-header {
  padding: 0 10px !important;
  @media screen and (min-width: 991px){
    padding: 0 50px !important;
  }
}

.menuBar{
  padding: 0 0;
}
.logo{
  height: auto;
  width: 150px;
  padding: 0 10px 0 0;
  float: left;
  color: white;
}
.logo span{
  display: inline-block;
  font-size: 20px;
  text-transform: capitalize;
}
.menuCon{
  /*width: calc(100% - 150px);
  float: left;*/
}
.menuCon .ant-menu-item{
  padding: 0px 5px;
}
.menuCon .ant-menu-submenu-title{
  padding: 0 20px;
}
.menuCon .ant-menu-item a, .menuCon .ant-menu-submenu-title a{
  padding: 0 15px;
}
.menuCon .ant-menu-horizontal{
  border-bottom: none;
}
.menuCon .leftMenu{
  float: left;
}
.menuCon .rightMenu{
  float: right;
}
.drawer-button-wrapper {
  float: right;
  display: none;
}
.barsBtn{
  display: block;
  width: 20px;
  height: 2px;
  background: #1890ff;
  position: relative;
}
.barsBtn:after,.barsBtn:before{
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}
.barsBtn:after{
  top: auto;
  bottom: -6px;
}
.ant-drawer-body{
  padding: 0;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item, .ant-drawer-body .ant-menu-horizontal > .ant-menu-submenu{
  display: inline-block;
  width: 100%;
}
.ant-drawer-body .ant-menu-horizontal{
  border-bottom: none;
}
.ant-drawer-body .ant-menu-horizontal > .ant-menu-item:hover{
  border-bottom-color: transparent;
}

@media(max-width: 767px){
  .language {
    text-align: center;
  }

  .drawer-button-wrapper{
    display: inline-block;
  }
  .leftMenu,.rightMenu{
    display: none;
  }
  .logo a{
    margin-left: -20px;
  }
  .menuCon .ant-menu-item, .menuCon .ant-menu-submenu-title{
    padding: 1px 20px;
  }
  .logo a{
    padding: 10px 20px;
  }
}

